import { lazy, Suspense } from 'react';
import './App.css';
import './css/bootstrap.min.css';
import './css/style.css'
import 'bootstrap/dist/js/bootstrap.bundle.min';

import React, { useContext } from 'react';
import { DataContext } from './context/dataProvider';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import WhatsAppChat from './components/WhatsAppChat.js';
import { HelmetProvider } from 'react-helmet-async';

const Navbar = lazy(() => import('./components/Nav.js'));
const Home = lazy(() => import('./components/Home.js'));
const About = lazy(() => import('./components/About.js'));
const Contact = lazy(() => import('./components/Contact.js'));
const Product = lazy(() => import('./components/Products.js'));
const Service = lazy(() => import('./components/Services.js'));
const Footer = lazy(() => import('./components/Footer.js'));
const Category = lazy(() => import('./components/Category.js'));

function App() {
  const data = useContext(DataContext);
  return (
    <HelmetProvider>
      <div className="App">
        <Router>
          <Navbar />
          <Suspense fallback={''}>
            <Routes>
              <Route path={`/`} element={<Home />} />
              <Route path={`/about`} element={<About />} />
              <Route path={`/contact`} element={<Contact />} />
              <Route path={`/products`} element={<Product />} />
              <Route path={`/services`} element={<Service />} />
              <Route path={`/products/:category`} element={<Category />} />

            </Routes>
            <Footer />
          </Suspense>
          <WhatsAppChat />
        </Router>

      </div>
    </HelmetProvider>
  );
}

export default App;
