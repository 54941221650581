import contextData from './data';
import React, { createContext } from 'react';

const DataContext = createContext();

const DataProvider = ({ children }) => {
  return (
    <DataContext.Provider value={contextData}>
      {children}
    </DataContext.Provider>
  );
};

export { DataContext, DataProvider };
