const contextData = {
  name:'bhargav',
  contact:'+91 123456789',
  products : {
    "Acid Dyes": [
        {
          "id": 1,
          "name": "Acid Milling Dyes",
          "image": "../../images/acid_milling_dyes.webp",
          "description": ""
        },
        {
          "id": 2,
          "name": "Acid Metal Complex Dyes",
          "image": "../../images/acid_metal_complex_dyes.webp",
          "description": ""
        },
        {
          "id": 3,
          "name": "Dyeing Chemical",
          "image": "../../images/dyeing_chemical.webp",
          "description": ""
        },
        {
          "id": 4,
          "name": "Acid Multi Color Dyes",
          "image": "../../images/acid_multi_color_dyes.webp",
          "description": ""
        },
        {
          "id": 5,
          "name": "Industrial Grade Acid Dye",
          "image": "../../images/industrial_grade_acid_dye.webp",
          "description": ""
        },
        {
          "id": 6,
          "name": "Fabric Brighteners",
          "image": "../../images/fabric_brighteners.webp",
          "description": ""
        },
        {
          "id": 7,
          "name": "Fluorescent Dye",
          "image": "../../images/fluorescent_dye.webp",
          "description": ""
        },
        {
          "id": 8,
          "name": "Acid Dyes",
          "image": "../../images/acid_dyes.webp",
          "description": ""
        },
        {
          "id": 9,
          "name": "Inkjet Dyes",
          "image": "../../images/inkjet_dyes.webp",
          "description": ""
        },
        {
          "id": 10,
          "name": "Solvent Soluble Dye",
          "image": "../../images/solvent_soluble_dye.webp",
          "description": ""
        },
        {
          "id": 11,
          "name": "Acid Orange",
          "image": "../../images/acid_orange.webp",
          "description": ""
        }
      ],
        "Solvent Dyes":[
        {
            "id": 1,
            "name": "Solvent Orange",
            "image": "../../images/solvent_orange.webp",
            "description": ""
        },
        {
            "id": 2,
            "name": "Solvent Yellow Dyes",
            "image": "../../images/solvent_yellow_dyes.webp",
            "description": ""
        },
        {
            "id": 3,
            "name": "Solvents Red B Dye",
            "image": "../../images/solvents_red_b_dye.webp",
            "description": ""
        },
        {
            "id": 4,
            "name": "Lake Chocolate Brown HT",
            "image": "../../images/lake_chocolate_brown_ht.webp",
            "description": ""
        },
        {
            "id": 5,
            "name": "Solvents Blue Dyes 2 GLN",
            "image": "../../images/solvents_blue_dyes_2_gln.webp",
            "description": ""
        },
        {
            "id": 6,
            "name": "Solvent Orange 54",
            "image": "../../images/solvent_orange_54.webp",
            "description": ""
        },
        {
            "id": 7,
            "name": "Solvent Orange Dyes",
            "image": "../../images/solvent_orange_dyes.webp",
            "description": ""
        },
        {
            "id": 8,
            "name": "Solvent Black Dyes",
            "image": "../../images/solvent_black_dyes.webp",
            "description": ""
        },
        {
            "id": 9,
            "name": "Solvents Black Rl Dye",
            "image": "../../images/solvents_black_rl_dye.webp",
            "description": ""
        },
        {
            "id": 10,
            "name": "Solvents Black RBL Dye",
            "image": "../../images/solvents_black_rbl_dye.webp",
            "description": ""
        },
        {
            "id": 11,
            "name": "Solvents Orange R Dyes",
            "image": "../../images/solvents_orange_r_dyes.webp",
            "description": ""
        },
        {
            "id": 12,
            "name": "Solvents Orange Dyes 2R",
            "image": "../../images/solvents_orange_dyes_2r.webp",
            "description": ""
        },
        {
            "id": 13,
            "name": "Solvent Green",
            "image": "../../images/solvent_green.webp",
            "description": ""
        },
        {
            "id": 14,
            "name": "Solvents Yellow R Dye",
            "image": "../../images/solvents_yellow_r_dye.webp",
            "description": ""
        },
        {
            "id": 15,
            "name": "Solvent Brown 41",
            "image": "../../images/solvent_brown_41.webp",
            "description": ""
        },
        {
            "id": 16,
            "name": "Solvents Yellow Gl Dyes",
            "image": "../../images/solvents_yellow_gl_dyes.webp",
            "description": ""
        },
        {
            "id": 17,
            "name": "Solvent Dyes",
            "image": "../../images/solvent_dyes.webp",
            "description": ""
        },
        {
            "id": 18,
            "name": "Solvents Yellow Dyes 2 RLS",
            "image": "../../images/solvents_yellow_dyes_2_rls.webp",
            "description": ""
        },
        {
            "id": 19,
            "name": "Solvents Fire Red G Dye",
            "image": "../../images/solvents_fire_red_g_dye.webp",
            "description": ""
        },
        {
            "id": 20,
            "name": "Solvent Red Dyes",
            "image": "../../images/solvent_red_dyes.webp",
            "description": ""
        },
        {
            "id": 21,
            "name": "Solvents Pink Dyes 5BLG",
            "image": "../../images/solvents_pink_dyes_5blg.webp",
            "description": ""
        },
        {
            "id": 22,
            "name": "Solvents Fire Red GLS Dyes",
            "image": "../../images/solvents_fire_red_gls_dyes.webp",
            "description": ""
        }
    ],
        "Optical Whitening Agent":[
        {
            "id": 1,
            "name": "Optical Whitening Agent",
            "image": "../../images/optical_whitening_agent.webp",
            "description": ""
        },
        {
            "id": 2,
            "name": "Optical Brighteners",
            "image": "../../images/optical_brighteners.webp",
            "description": ""
        }
    ],
        "Reactive Dyes":[
        {
            "id": 1,
            "name": "Direct Reactive Dye",
            "image": "../../images/direct_reactive_dye.webp",
            "description": ""
        },
        {
            "id": 2,
            "name": "Reactive Hot Dyes",
            "image": "../../images/reactive_hot_dyes.webp",
            "description": ""
        },
        {
            "id": 3,
            "name": "Industrial Reactive Dyes",
            "image": "../../images/industrial_reactive_dyes.webp",
            "description": ""
        },
        {
            "id": 4,
            "name": "Golden Yellow MR",
            "image": "../../images/golden_yellow_mr.webp",
            "description": ""
        },
        {
            "id": 5,
            "name": "Yellow MGR",
            "image": "../../images/yellow_mgr.webp",
            "description": ""
        },
        {
            "id": 6,
            "name": "Bifunctional Reactive Dyes",
            "image": "../../images/bifunctional_reactive_dyes.webp",
            "description": ""
        },
        {
            "id": 7,
            "name": "REACTIVE YELLOW M3R",
            "image": "../../images/reactive_yellow_m3r.webp",
            "description": ""
        },
        {
            "id": 8,
            "name": "Reactive Dyes",
            "image": "../../images/reactive_dyes.webp",
            "description": ""
        },
        {
            "id": 9,
            "name": "Reactive Yellow MG",
            "image": "../../images/reactive_yellow_mg.webp",
            "description": ""
        },
        {
            "id": 10,
            "name": "Multi Color Reactive Dyes",
            "image": "../../images/multi_color_reactive_dyes.webp",
            "description": ""
        },
        {
            "id": 11,
            "name": "M Cold Series Reactive Dyes",
            "image": "../../images/m_cold_series_reactive_dyes.webp",
            "description": ""
        },
        {
            "id": 12,
            "name": "Vinyl Sulphone Dyes",
            "image": "../../images/vinyl_sulphone_dyes.webp",
            "description": ""
        },
        {
            "id": 13,
            "name": "Highly Exhausted Dyes",
            "image": "../../images/highly_exhausted_dyes.webp",
            "description": ""
        },
        {
            "id": 14,
            "name": "M Cold Series Dyes",
            "image": "../../images/m_cold_series_dyes.webp",
            "description": ""
        },
        {
            "id": 15,
            "name": "Bi Functional ME Series Reactive Dyes",
            "image": "../../images/bi_functional_me_series_reactive_dyes.webp",
            "description": ""
        },
        {
            "id": 16,
            "name": "Reactive Dyes Yellow 7",
            "image": "../../images/reactive_dyes_yellow_7.webp",
            "description": ""
        },
        {
            "id": 17,
            "name": "Bi Functional ME Series Dyes",
            "image": "../../images/bi_functional_me_series_dyes.webp",
            "description": ""
        },
        {
            "id": 18,
            "name": "Reactive Yellow M4R",
            "image": "../../images/reactive_yellow_m4r.webp",
            "description": ""
        },
        {
            "id": 19,
            "name": "Fabric Reactive Dyes",
            "image": "../../images/fabric_reactive_dyes.webp",
            "description": ""
        },
        {
            "id": 20,
            "name": "Reactive Dye Yellow 22",
            "image": "../../images/reactive_dye_yellow_22.webp",
            "description": ""
        },
        {
            "id": 21,
            "name": "Yellow Color Reactive Dyes",
            "image": "../../images/yellow_color_reactive_dyes.webp",
            "description": ""
        },
        {
            "id": 22,
            "name": "Reactive Orange 86",
            "image": "../../images/reactive_orange_86.webp",
            "description": ""
        },
        {
            "id": 23,
            "name": "Yellow M8G Reactive Dyes",
            "image": "../../images/yellow_m8g_reactive_dyes.webp",
            "description": ""
        },
        {
            "id": 24,
            "name": "Liquid Reactive Dyes",
            "image": "../../images/liquid_reactive_dyes.webp",
            "description": ""
        },
        {
            "id": 25,
            "name": "Reactive Orange 1",
            "image": "../../images/reactive_orange_1.webp",
            "description": ""
        },
        {
            "id": 26,
            "name": "Purple H3R Reactive Dyes",
            "image": "../../images/purple_h3r_reactive_dyes.webp",
            "description": ""
        },
        {
            "id": 27,
            "name": "H Cold Series Reactive Dyes",
            "image": "../../images/h_cold_series_reactive_dyes.webp",
            "description": ""
        },
        {
            "id": 28,
            "name": "Reactive Orange 14",
            "image": "../../images/reactive_orange_14.webp",
            "description": ""
        },
        {
            "id": 29,
            "name": "Yellow M4G Reactive Dyes",
            "image": "../../images/yellow_m4g_reactive_dyes.webp",
            "description": ""
        },
        {
            "id": 30,
            "name": "Eco Friendly Reactive Dyes",
            "image": "../../images/eco_friendly_reactive_dyes.webp",
            "description": ""
        },
        {
            "id": 31,
            "name": "Reactive Yellow 44",
            "image": "../../images/reactive_yellow_44.webp",
            "description": ""
        },
        {
            "id": 32,
            "name": "Highly Exhausted Reactive Dyes",
            "image": "../../images/highly_exhausted_reactive_dyes.webp",
            "description": ""
        }
    ],
        "Hair Dyes":[
        {
            "id": 1,
            "name": "Basic Orange 31 Hair Dye",
            "image": "../../images/basic_orange_31_hair_dye.webp",
            "description": ""
        },
        {
            "id": 2,
            "name": "Basic Brown 16 Hair Dye",
            "image": "../../images/basic_brown_16_hair_dye.webp",
            "description": ""
        },
        {
            "id": 3,
            "name": "2 Nitro Para Phenyldiamine",
            "image": "../../images/2_nitro_para_phenyldiamine.webp",
            "description": ""
        },
        {
            "id": 4,
            "name": "Para Phenyldiamine",
            "image": "../../images/para_phenyldiamine.webp",
            "description": ""
        },
        {
            "id": 5,
            "name": "Basic Red 76 Hair Dyes",
            "image": "../../images/basic_red_76_hair_dyes.webp",
            "description": ""
        },
        {
            "id": 6,
            "name": "Basic Brown 17 Hair Dyes",
            "image": "../../images/basic_brown_17_hair_dyes.webp",
            "description": ""
        }
    ],
        "Ultramarine Blue":[
        {
            "id": 1,
            "name": "Masterbatch Additive",
            "image": "../../images/masterbatch_additive.webp",
            "description": ""
        },
        {
            "id": 2,
            "name": "Ultramarine for Surface Coating",
            "image": "../../images/ultramarine_for_surface_coating.webp",
            "description": ""
        },
        {
            "id": 3,
            "name": "Ultramarine Blue For Paper Industry",
            "image": "../../images/ultramarine_blue_for_paper_industry.webp",
            "description": ""
        },
        {
            "id": 4,
            "name": "Ultramarine Blue Paint Pigments",
            "image": "../../images/ultramarine_blue_paint_pigments.webp",
            "description": ""
        },
        {
            "id": 5,
            "name": "Ultramarine Blue for Rubber",
            "image": "../../images/ultramarine_blue_for_rubber.webp",
            "description": ""
        },
        {
            "id": 6,
            "name": "Pigment Inks",
            "image": "../../images/pigment_inks.webp",
            "description": ""
        }
    ],
        "Paper Dyes":[
        {
            "id": 1,
            "name": "Paper Dyes",
            "image": "../../images/paper_dyes.webp",
            "description": ""
        }
    ],
        "PH & Stain Indicators":[
        {
            "id": 1,
            "name": "Methyl Red",
            "image": "../../images/methyl_red.webp",
            "description": ""
        },
        {
            "id": 2,
            "name": "Methyl Orange",
            "image": "../../images/methyl_orange.webp",
            "description": ""
        }
    ],
        "Dyestuffs":[
        {
            "id": 1,
            "name": "Colorants Dyes",
            "image": "../../images/colorants_dyes.webp",
            "description": ""
        },
        {
            "id": 2,
            "name": "Corantes",
            "image": "../../images/corantes.webp",
            "description": ""
        },
        {
            "id": 3,
            "name": "Dyestuffs",
            "image": "../../images/dyestuffs.webp",
            "description": ""
        },
        {
            "id": 4,
            "name": "Colorantes Dyes",
            "image": "../../images/colorantes_dyes.webp",
            "description": ""
        },
        {
            "id": 5,
            "name": "Coloranti Dyes",
            "image": "../../images/coloranti_dyes.webp",
            "description": ""
        }
    ],
        "Pigments":[
        {
            "id": 1,
            "name": "Fluorescent Pigments",
            "image": "../../images/fluorescent_pigments.webp",
            "description": ""
        },
        {
            "id": 2,
            "name": "Inorganic Pigments",
            "image": "../../images/inorganic_pigments.webp",
            "description": ""
        },
        {
            "id": 3,
            "name": "Textile Pigments",
            "image": "../../images/textile_pigments.webp",
            "description": ""
        },
        {
            "id": 4,
            "name": "Pigment Emulsion",
            "image": "../../images/pigment_emulsion.webp",
            "description": ""
        },
        {
            "id": 5,
            "name": "Pigments Dyes",
            "image": "../../images/pigments_dyes.webp",
            "description": ""
        },
        {
            "id": 6,
            "name": "Chrome Pigment",
            "image": "../../images/chrome_pigment.webp",
            "description": ""
        },
        {
            "id": 7,
            "name": "Black Pigment",
            "image": "../../images/black_pigment.webp",
            "description": ""
        },
        {
            "id": 8,
            "name": "Chromium Pigments",
            "image": "../../images/chromium_pigments.webp",
            "description": ""
        },
        {
            "id": 9,
            "name": "Organic Pigment Powder",
            "image": "../../images/organic_pigment_powder.webp",
            "description": ""
        },
        {
            "id": 10,
            "name": "Blue Pigments",
            "image": "../../images/blue_pigments.webp",
            "description": ""
        },
        {
            "id": 11,
            "name": "Food Pigment",
            "image": "../../images/food_pigment.webp",
            "description": ""
        },
        {
            "id": 12,
            "name": "Organic Pigment Paste",
            "image": "../../images/organic_pigment_paste.webp",
            "description": ""
        },
        {
            "id": 13,
            "name": "Pigment Powder",
            "image": "../../images/pigment_powder.webp",
            "description": ""
        }
    ],
        "Biological Stains":[
        {
            "id": 1,
            "name": "Orange G Dyes",
            "image": "../../images/orange_g_dyes.webp",
            "description": ""
        },
        {
            "id": 2,
            "name": "Diethyl Safranine",
            "image": "../../images/diethyl_safranine.webp",
            "description": ""
        },
        {
            "id": 3,
            "name": "Meldola's Blue",
            "image": "../../images/meldolas_blue.webp",
            "description": ""
        },
        {
            "id": 4,
            "name": "Trypan Blue",
            "image": "../../images/trypan_blue.webp",
            "description": ""
        },
        {
            "id": 5,
            "name": "Janus Green B",
            "image": "../../images/janus_green_b.webp",
            "description": ""
        },
        {
            "id": 6,
            "name": "Basic Violet 5 / Dimethyl Safranine",
            "image": "../../images/basic_violet_5_dimethyl_safranine.webp",
            "description": ""
        },
        {
            "id": 7,
            "name": "Safranine O",
            "image": "../../images/safranine_o.webp",
            "description": ""
        },
        {
            "id": 8,
            "name": "Titan Yellow",
            "image": "../../images/titan_yellow.webp",
            "description": ""
        },
        {
            "id": 9,
            "name": "Methylene Blue",
            "image": "../../images/methylene_blue.webp",
            "description": ""
        }
    ],
        "Basic Dyes":[
        {
            "id": 1,
            "name": "Liquid Dyes",
            "image": "../../images/liquid_dyes.webp",
            "description": ""
        },
        {
            "id": 2,
            "name": "Basic Violet 1 Liquid Dyes",
            "image": "../../images/basic_violet_1_liquid_dyes.webp",
            "description": ""
        },
        {
            "id": 3,
            "name": "Bismark Brown RG Dyes",
            "image": "../../images/bismark_brown_rg_dyes.webp",
            "description": ""
        },
        {
            "id": 4,
            "name": "Gentian Violet Dye",
            "image": "../../images/gentian_violet_dye.webp",
            "description": ""
        },
        {
            "id": 5,
            "name": "Basic Green Dyes",
            "image": "../../images/basic_green_dyes.webp",
            "description": ""
        },
        {
            "id": 6,
            "name": "Basic Black Dyes",
            "image": "../../images/basic_black_dyes.webp",
            "description": ""
        },
        {
            "id": 7,
            "name": "Basic Brown Dyes",
            "image": "../../images/basic_brown_dyes.webp",
            "description": ""
        },
        {
            "id": 8,
            "name": "Basic Voilet Dyes",
            "image": "../../images/basic_voilet_dyes.webp",
            "description": ""
        },
        {
            "id": 9,
            "name": "Basic Dyes",
            "image": "../../images/basic_dyes.webp",
            "description": ""
        },
        {
            "id": 10,
            "name": "Basic Orange Dyes",
            "image": "../../images/basic_orange_dyes.webp",
            "description": ""
        },
        {
            "id": 11,
            "name": "Colorants for Coolant and Engine Oil",
            "image": "../../images/colorants_for_coolant_and_engine_oil.webp",
            "description": ""
        },
        {
            "id": 12,
            "name": "Basic Violet Dyes",
            "image": "../../images/basic_violet_dyes.webp",
            "description": ""
        },
        {
            "id": 13,
            "name": "Basic Blue Dyes",
            "image": "../../images/basic_blue_dyes.webp",
            "description": ""
        },
        {
            "id": 14,
            "name": "Liquid Basic Dyes",
            "image": "../../images/liquid_basic_dyes.webp",
            "description": ""
        }
    ],
        "Dye Intermediates":[
        {
            "id": 1,
            "name": "Reactive Dye Intermediates",
            "image": "../../images/reactive_dye_intermediates.webp",
            "description": ""
        },
        {
            "id": 2,
            "name": "Dyes Intermediate",
            "image": "../../images/dyes_intermediate.webp",
            "description": ""
        }
    ],
        "Biological Reagents":[
        {
            "id": 1,
            "name": "Stain and Indicators",
            "image": "../../images/stain_and_indicators.webp",
            "description": ""
        },
        {
            "id": 2,
            "name": "Biological Stains",
            "image": "../../images/biological_stains.webp",
            "description": ""
        }
    ],
        "Fine Chemical":[
        {
            "id": 1,
            "name": "Rhodamine Dyes",
            "image": "../../images/rhodamine_dyes.webp",
            "description": ""
        },
        {
            "id": 2,
            "name": "Vinyl Sulphone Reactive Dyes",
            "image": "../../images/vinyl_sulphone_reactive_dyes.webp",
            "description": ""
        },
        {
            "id": 3,
            "name": "Optical Brightening Agents",
            "image": "../../images/optical_brightening_agents.webp",
            "description": ""
        },
        {
            "id": 4,
            "name": "Thermochromic Dyes",
            "image": "../../images/thermochromic_dyes.webp",
            "description": ""
        },
        {
            "id": 5,
            "name": "Colorants",
            "image": "../../images/colorants.webp",
            "description": ""
        },
        {
            "id": 6,
            "name": "Reactive Black Dyes",
            "image": "../../images/reactive_black_dyes.webp",
            "description": ""
        },
        {
            "id": 7,
            "name": "Red Oxide Pigments",
            "image": "../../images/red_oxide_pigments.webp",
            "description": ""
        },
        {
            "id": 8,
            "name": "Direct Blue Dyes",
            "image": "../../images/direct_blue_dyes.webp",
            "description": ""
        },
        {
            "id": 9,
            "name": "Lake Erythrosine",
            "image": "../../images/lake_erythrosine.webp",
            "description": ""
        },
        {
            "id": 10,
            "name": "Solvent Red",
            "image": "../../images/solvent_red.webp",
            "description": ""
        },
        {
            "id": 11,
            "name": "Pigment Emulsions",
            "image": "../../images/pigment_emulsions.webp",
            "description": ""
        },
        {
            "id": 12,
            "name": "Safranin",
            "image": "../../images/safranin.webp",
            "description": ""
        },
        {
            "id": 13,
            "name": "Methyl Violet Dyes",
            "image": "../../images/methyl_violet_dyes.webp",
            "description": ""
        },
        {
            "id": 14,
            "name": "Acid Green Dyes",
            "image": "../../images/acid_green_dyes.webp",
            "description": ""
        },
        {
            "id": 15,
            "name": "Iron Oxide Pigment",
            "image": "../../images/iron_oxide_pigment.webp",
            "description": ""
        },
        {
            "id": 16,
            "name": "Liquid Acrylic Dyes",
            "image": "../../images/liquid_acrylic_dyes.webp",
            "description": ""
        },
        {
            "id": 17,
            "name": "Wax Printing Dyes",
            "image": "../../images/wax_printing_dyes.webp",
            "description": ""
        },
        {
            "id": 18,
            "name": "Cold Dyes",
            "image": "../../images/cold_dyes.webp",
            "description": ""
        },
        {
            "id": 19,
            "name": "Sunset Yellow FCF",
            "image": "../../images/sunset_yellow_fcf.webp",
            "description": ""
        },
        {
            "id": 20,
            "name": "Violet Pigment",
            "image": "../../images/violet_pigment.webp",
            "description": ""
        },
        {
            "id": 21,
            "name": "Direct Liquid Dyes",
            "image": "../../images/direct_liquid_dyes.webp",
            "description": ""
        },
        {
            "id": 22,
            "name": "Sunfast Direct Dyes",
            "image": "../../images/sunfast_direct_dyes.webp",
            "description": ""
        },
        {
            "id": 23,
            "name": "Color Pigment",
            "image": "../../images/color_pigment.webp",
            "description": ""
        },
        {
            "id": 24,
            "name": "Printing Inks Pigment",
            "image": "../../images/printing_inks_pigment.webp",
            "description": ""
        },
        {
            "id": 25,
            "name": "Acid Brown Dyes",
            "image": "../../images/acid_brown_dyes.webp",
            "description": ""
        },
        {
            "id": 26,
            "name": "FRP Pigment",
            "image": "../../images/frp_pigment.webp",
            "description": ""
        },
        {
            "id": 27,
            "name": "Petroleum Dyes",
            "image": "../../images/petroleum_dyes.webp",
            "description": ""
        },
        {
            "id": 28,
            "name": "Pigment Orange",
            "image": "../../images/pigment_orange.webp",
            "description": ""
        },
        {
            "id": 29,
            "name": "Plastic Pigments",
            "image": "../../images/plastic_pigments.webp",
            "description": ""
        },
        {
            "id": 30,
            "name": "Ink Dyes",
            "image": "../../images/ink_dyes.webp",
            "description": ""
        },
        {
            "id": 31,
            "name": "Reactive Cold Dyes",
            "image": "../../images/reactive_cold_dyes.webp",
            "description": ""
        },
        {
            "id": 32,
            "name": "Plastic Coloring Dyes",
            "image": "../../images/plastic_coloring_dyes.webp",
            "description": ""
        },
        {
            "id": 33,
            "name": "Cationic Dyestuff",
            "image": "../../images/cationic_dyestuff.webp",
            "description": ""
        },
        {
            "id": 34,
            "name": "Nylon Dyes",
            "image": "../../images/nylon_dyes.webp",
            "description": ""
        },
        {
            "id": 35,
            "name": "Basic Vat Dyes",
            "image": "../../images/basic_vat_dyes.webp",
            "description": ""
        },
        {
            "id": 36,
            "name": "Patent Blue",
            "image": "../../images/patent_blue.webp",
            "description": ""
        },
        {
            "id": 37,
            "name": "Fast Scarlet G Base",
            "image": "../../images/fast_scarlet_g_base.webp",
            "description": ""
        },
        {
            "id": 38,
            "name": "Soluble Dyes",
            "image": "../../images/soluble_dyes.webp",
            "description": ""
        },
        {
            "id": 39,
            "name": "Crystal Violet",
            "image": "../../images/crystal_violet.webp",
            "description": ""
        },
        {
            "id": 40,
            "name": "Masterbatch",
            "image": "../../images/masterbatch.webp",
            "description": ""
        },
        {
            "id": 41,
            "name": "Rubber Pigments",
            "image": "../../images/rubber_pigments.webp",
            "description": ""
        },
        {
            "id": 42,
            "name": "Phenylenediamine",
            "image": "../../images/phenylenediamine.webp",
            "description": ""
        },
        {
            "id": 43,
            "name": "Smoke Dyes",
            "image": "../../images/smoke_dyes.webp",
            "description": ""
        },
        {
            "id": 44,
            "name": "Pigment Rubine",
            "image": "../../images/pigment_rubine.webp",
            "description": ""
        },
        {
            "id": 45,
            "name": "Candle Dyes",
            "image": "../../images/candle_dyes.webp",
            "description": ""
        },
        {
            "id": 46,
            "name": "Brown Pigment Paste",
            "image": "../../images/brown_pigment_paste.webp",
            "description": ""
        },
        {
            "id": 47,
            "name": "Acid Violet Dyes",
            "image": "../../images/acid_violet_dyes.webp",
            "description": ""
        },
        {
            "id": 48,
            "name": "Metal Complex Dyes",
            "image": "../../images/metal_complex_dyes.webp",
            "description": ""
        },
        {
            "id": 49,
            "name": "Amaranth",
            "image": "../../images/amaranth.webp",
            "description": ""
        },
        {
            "id": 50,
            "name": "Reactive Blue Dyestuff",
            "image": "../../images/reactive_blue_dyestuff.webp",
            "description": ""
        },
        {
            "id": 51,
            "name": "Acid Black Dyes",
            "image": "../../images/acid_black_dyes.webp",
            "description": ""
        },
        {
            "id": 52,
            "name": "Water Dyes",
            "image": "../../images/water_dyes.webp",
            "description": ""
        },
        {
            "id": 53,
            "name": "Organic Chemical Dyes",
            "image": "../../images/organic_chemical_dyes.webp",
            "description": ""
        },
        {
            "id": 54,
            "name": "Synthetic Dyestuff",
            "image": "../../images/synthetic_dyestuff.webp",
            "description": ""
        },
        {
            "id": 55,
            "name": "Reactive Blue Dye",
            "image": "../../images/reactive_blue_dye.webp",
            "description": ""
        },
        {
            "id": 56,
            "name": "Alpha Blue Pigments",
            "image": "../../images/alpha_blue_pigments.webp",
            "description": ""
        },
        {
            "id": 57,
            "name": "Ultramarine Pigment",
            "image": "../../images/ultramarine_pigment.webp",
            "description": ""
        },
        {
            "id": 58,
            "name": "Paint Pigment",
            "image": "../../images/paint_pigment.webp",
            "description": ""
        },
        {
            "id": 59,
            "name": "Chrome Dyes",
            "image": "../../images/chrome_dyes.webp",
            "description": ""
        },
        {
            "id": 60,
            "name": "Powdered Dyes",
            "image": "../../images/powdered_dyes.webp",
            "description": ""
        },
        {
            "id": 61,
            "name": "Sulphur Dyes",
            "image": "../../images/sulphur_dyes.webp",
            "description": ""
        },
        {
            "id": 62,
            "name": "Pigment Beta Blue",
            "image": "../../images/pigment_beta_blue.webp",
            "description": ""
        },
        {
            "id": 63,
            "name": "Guar Gum Powder",
            "image": "../../images/guar_gum_powder.webp",
            "description": ""
        },
        {
            "id": 64,
            "name": "Reactive H Dyes",
            "image": "../../images/reactive_h_dyes.webp",
            "description": ""
        },
        {
            "id": 65,
            "name": "Wood Dyes",
            "image": "../../images/wood_dyes.webp",
            "description": ""
        },
        {
            "id": 66,
            "name": "Pigment Ink",
            "image": "../../images/pigment_ink.webp",
            "description": ""
        },
        {
            "id": 67,
            "name": "Rubber Dyes",
            "image": "../../images/rubber_dyes.webp",
            "description": ""
        },
        {
            "id": 68,
            "name": "Auramine Dyes",
            "image": "../../images/auramine_dyes.webp",
            "description": ""
        },
        {
            "id": 69,
            "name": "Turquoise Blue Dyes",
            "image": "../../images/turquoise_blue_dyes.webp",
            "description": ""
        },
        {
            "id": 70,
            "name": "Phthalocyanine Pigment",
            "image": "../../images/phthalocyanine_pigment.webp",
            "description": ""
        },
        {
            "id": 71,
            "name": "Sulphur Black Dyes",
            "image": "../../images/sulphur_black_dyes.webp",
            "description": ""
        },
        {
            "id": 72,
            "name": "Organic Pigments",
            "image": "../../images/organic_pigments.webp",
            "description": ""
        },
        {
            "id": 73,
            "name": "Blue Dyes",
            "image": "../../images/blue_dyes.webp",
            "description": ""
        },
        {
            "id": 74,
            "name": "Phenol Red",
            "image": "../../images/phenol_red.webp",
            "description": ""
        },
        {
            "id": 75,
            "name": "Clothing Dye",
            "image": "../../images/clothing_dye.webp",
            "description": ""
        },
        {
            "id": 76,
            "name": "Dye Chemicals",
            "image": "../../images/dye_chemicals.webp",
            "description": ""
        },
        {
            "id": 77,
            "name": "Carbon Black Pigment",
            "image": "../../images/carbon_black_pigment.webp",
            "description": ""
        },
        {
            "id": 78,
            "name": "Blue Pigment Paste",
            "image": "../../images/blue_pigment_paste.webp",
            "description": ""
        },
        {
            "id": 79,
            "name": "Violet Pigment Paste",
            "image": "../../images/violet_pigment_paste.webp",
            "description": ""
        },
        {
            "id": 80,
            "name": "Rapid Dyes",
            "image": "../../images/rapid_dyes.webp",
            "description": ""
        },
        {
            "id": 81,
            "name": "Vinyl Sulphone",
            "image": "../../images/vinyl_sulphone.webp",
            "description": ""
        },
        {
            "id": 82,
            "name": "Sulphur Black",
            "image": "../../images/sulphur_black.webp",
            "description": ""
        },
        {
            "id": 83,
            "name": "Vat Dyes",
            "image": "../../images/vat_dyes.webp",
            "description": ""
        },
        {
            "id": 84,
            "name": "Indigo Dyes",
            "image": "../../images/indigo_dyes.webp",
            "description": ""
        },
        {
            "id": 85,
            "name": "Basic Orange",
            "image": "../../images/basic_orange.webp",
            "description": ""
        },
        {
            "id": 86,
            "name": "AZO Dyes",
            "image": "../../images/azo_dyes.webp",
            "description": ""
        },
        {
            "id": 87,
            "name": "Natural Textile Dyes",
            "image": "../../images/natural_textile_dyes.webp",
            "description": ""
        },
        {
            "id": 88,
            "name": "Reactive Yellow Dyes",
            "image": "../../images/reactive_yellow_dyes.webp",
            "description": ""
        },
        {
            "id": 89,
            "name": "Yellow Dyes",
            "image": "../../images/yellow_dyes.webp",
            "description": ""
        },
        {
            "id": 90,
            "name": "Metal Dyes",
            "image": "../../images/metal_dyes.webp",
            "description": ""
        },
        {
            "id": 91,
            "name": "Hot Brand Reactive Dyes",
            "image": "../../images/hot_brand_reactive_dyes.webp",
            "description": ""
        },
        {
            "id": 92,
            "name": "Direct Yellow Dyes",
            "image": "../../images/direct_yellow_dyes.webp",
            "description": ""
        },
        {
            "id": 93,
            "name": "Dispersion Pigment",
            "image": "../../images/dispersion_pigment.webp",
            "description": ""
        },
        {
            "id": 94,
            "name": "Plastic Dyes",
            "image": "../../images/plastic_dyes.webp",
            "description": ""
        },
        {
            "id": 95,
            "name": "Lake Pigments",
            "image": "../../images/lake_pigments.webp",
            "description": ""
        },
        {
            "id": 96,
            "name": "Soap Dyes",
            "image": "../../images/soap_dyes.webp",
            "description": ""
        },
        {
            "id": 97,
            "name": "Synthetic Organic Dyes",
            "image": "../../images/synthetic_organic_dyes.webp",
            "description": ""
        },
        {
            "id": 98,
            "name": "Natural Pigment",
            "image": "../../images/natural_pigment.webp",
            "description": ""
        },
        {
            "id": 99,
            "name": "Basic Violet",
            "image": "../../images/basic_violet.webp",
            "description": ""
        },
        {
            "id": 100,
            "name": "Ultramarine Blue",
            "image": "../../images/ultramarine_blue.webp",
            "description": ""
        },
        {
            "id": 101,
            "name": "Garment Dyes",
            "image": "../../images/garment_dyes.webp",
            "description": ""
        },
        {
            "id": 102,
            "name": "Cotton Dyes",
            "image": "../../images/cotton_dyes.webp",
            "description": ""
        },
        {
            "id": 103,
            "name": "Printing Dyes",
            "image": "../../images/printing_dyes.webp",
            "description": ""
        },
        {
            "id": 104,
            "name": "Cobalt",
            "image": "../../images/cobalt.webp",
            "description": ""
        },
        {
            "id": 105,
            "name": "Polyester Dyes",
            "image": "../../images/polyester_dyes.webp",
            "description": ""
        },
        {
            "id": 106,
            "name": "Moulded Dyes",
            "image": "../../images/moulded_dyes.webp",
            "description": ""
        },
        {
            "id": 107,
            "name": "Vinyl Sulfone Dyes",
            "image": "../../images/vinyl_sulfone_dyes.webp",
            "description": ""
        },
        {
            "id": 108,
            "name": "Reactive Orange Dyes",
            "image": "../../images/reactive_orange_dyes.webp",
            "description": ""
        },
        {
            "id": 109,
            "name": "Violet Dyes",
            "image": "../../images/violet_dyes.webp",
            "description": ""
        },
        {
            "id": 110,
            "name": "Phthalocyanine Blue",
            "image": "../../images/phthalocyanine_blue.webp",
            "description": ""
        },
        {
            "id": 111,
            "name": "Textile Dyes",
            "image": "../../images/textile_dyes.webp",
            "description": ""
        },
        {
            "id": 112,
            "name": "Remazol Dyes",
            "image": "../../images/remazol_dyes.webp",
            "description": ""
        },
        {
            "id": 113,
            "name": "Rubine Toner",
            "image": "../../images/rubine_toner.webp",
            "description": ""
        },
        {
            "id": 114,
            "name": "Reactive M Dyes",
            "image": "../../images/reactive_m_dyes.webp",
            "description": ""
        },
        {
            "id": 115,
            "name": "Wate Dyes",
            "image": "../../images/wate_dyes.webp",
            "description": ""
        },
        {
            "id": 116,
            "name": "Cold Brand Dyes",
            "image": "../../images/cold_brand_dyes.webp",
            "description": ""
        },
        {
            "id": 117,
            "name": "Synthetic Dyes",
            "image": "../../images/synthetic_dyes.webp",
            "description": ""
        },
        {
            "id": 118,
            "name": "Natural Blue Dyes",
            "image": "../../images/natural_blue_dyes.webp",
            "description": ""
        },
        {
            "id": 119,
            "name": "Reactive Red Dyes",
            "image": "../../images/reactive_red_dyes.webp",
            "description": ""
        },
        {
            "id": 120,
            "name": "Orange Flavour",
            "image": "../../images/orange_flavour.webp",
            "description": ""
        },
        {
            "id": 121,
            "name": "Blue Pigment",
            "image": "../../images/blue_pigment.webp",
            "description": ""
        },
        {
            "id": 122,
            "name": "Lake Colour",
            "image": "../../images/lake_colour.webp",
            "description": ""
        },
        {
            "id": 123,
            "name": "Colour Pigments",
            "image": "../../images/colour_pigments.webp",
            "description": ""
        },
        {
            "id": 124,
            "name": "Copper Phthalocyanine Pigment",
            "image": "../../images/copper_phthalocyanine_pigment.webp",
            "description": ""
        },
        {
            "id": 125,
            "name": "Golden Brown Pigment",
            "image": "../../images/golden_brown_pigment.webp",
            "description": ""
        },
        {
            "id": 126,
            "name": "Rapid Fast Dyes",
            "image": "../../images/rapid_fast_dyes.webp",
            "description": ""
        },
        {
            "id": 127,
            "name": "Pigment Red",
            "image": "../../images/pigment_red.webp",
            "description": ""
        }
    ],
        "Mordant Dyes":[
        {
            "id": 1,
            "name": "Mordant Yellow 12 Dyes",
            "image": "../../images/mordant_yellow_12_dyes.webp",
            "description": ""
        },
        {
            "id": 2,
            "name": "Acid Mordant Dyes",
            "image": "../../images/acid_mordant_dyes.webp",
            "description": ""
        },
        {
            "id": 3,
            "name": "Lake Quinoline Yellow",
            "image": "../../images/lake_quinoline_yellow.webp",
            "description": ""
        },
        {
            "id": 4,
            "name": "Lake Sunset Yellow",
            "image": "../../images/lake_sunset_yellow.webp",
            "description": ""
        },
        {
            "id": 5,
            "name": "Mordant Yellow 3 Dyes",
            "image": "../../images/mordant_yellow_3_dyes.webp",
            "description": ""
        },
        {
            "id": 6,
            "name": "Mordant Yellow 8 Dyes",
            "image": "../../images/mordant_yellow_8_dyes.webp",
            "description": ""
        }
    ],
        "Food Colours":[
        {
            "id": 1,
            "name": "Quinoline Yellow",
            "image": "../../images/quinoline_yellow.webp",
            "description": ""
        },
        {
            "id": 2,
            "name": "Brown HT",
            "image": "../../images/brown_ht.webp",
            "description": ""
        },
        {
            "id": 3,
            "name": "Black PN",
            "image": "../../images/black_pn.webp",
            "description": ""
        },
        {
            "id": 4,
            "name": "Natural Extracts",
            "image": "../../images/natural_extracts.webp",
            "description": ""
        },
        {
            "id": 5,
            "name": "Yellow Food Color",
            "image": "../../images/yellow_food_color.webp",
            "description": ""
        },
        {
            "id": 6,
            "name": "Primary Food Color",
            "image": "../../images/primary_food_color.webp",
            "description": ""
        },
        {
            "id": 7,
            "name": "Blended Food Colors",
            "image": "../../images/blended_food_colors.webp",
            "description": ""
        },
        {
            "id": 8,
            "name": "Brilliant Blue",
            "image": "../../images/brilliant_blue.webp",
            "description": ""
        },
        {
            "id": 9,
            "name": "Food Color Dyes",
            "image": "../../images/food_color_dyes.webp",
            "description": ""
        },
        {
            "id": 10,
            "name": "Tartrazine Food Color",
            "image": "../../images/tartrazine_food_color.webp",
            "description": ""
        },
        {
            "id": 11,
            "name": "Food Color Additives",
            "image": "../../images/food_color_additives.webp",
            "description": ""
        },
        {
            "id": 12,
            "name": "Natural Food Colour",
            "image": "../../images/natural_food_colour.webp",
            "description": ""
        },
        {
            "id": 13,
            "name": "Allura Red",
            "image": "../../images/allura_red.webp",
            "description": ""
        },
        {
            "id": 14,
            "name": "Colours for Seafood Industries",
            "image": "../../images/colours_for_seafood_industries.webp",
            "description": ""
        },
        {
            "id": 15,
            "name": "Patent Blue V",
            "image": "../../images/patent_blue_v.webp",
            "description": ""
        },
        {
            "id": 16,
            "name": "Ponceau 4R",
            "image": "../../images/ponceau_4r.webp",
            "description": ""
        },
        {
            "id": 17,
            "name": "Green S",
            "image": "../../images/green_s.webp",
            "description": ""
        },
        {
            "id": 18,
            "name": "Food Colours",
            "image": "../../images/food_colours.webp",
            "description": ""
        },
        {
            "id": 19,
            "name": "Carmoisine",
            "image": "../../images/carmoisine.webp",
            "description": ""
        },
        {
            "id": 20,
            "name": "Lake Colours",
            "image": "../../images/lake_colours.webp",
            "description": ""
        },
        {
            "id": 21,
            "name": "Food Dyes",
            "image": "../../images/food_dyes.webp",
            "description": ""
        },
        {
            "id": 22,
            "name": "Red Food Color",
            "image": "../../images/red_food_color.webp",
            "description": ""
        },
        {
            "id": 23,
            "name": "Red 2G",
            "image": "../../images/red_2g.webp",
            "description": ""
        },
        {
            "id": 24,
            "name": "Food Additives",
            "image": "../../images/food_additives.webp",
            "description": ""
        },
        {
            "id": 25,
            "name": "Erythrosine",
            "image": "../../images/erythrosine.webp",
            "description": ""
        },
        {
            "id": 26,
            "name": "Amaranth Food Colours",
            "image": "../../images/amaranth_food_colours.webp",
            "description": ""
        }
    ],
        "Dyes":[
        {
            "id": 1,
            "name": "Fast Salt Dyes",
            "image": "../../images/fast_salt_dyes.webp",
            "description": ""
        },
        {
            "id": 2,
            "name": "Indigo Vat Dyes",
            "image": "../../images/indigo_vat_dyes.webp",
            "description": ""
        },
        {
            "id": 3,
            "name": "Leather Dyes",
            "image": "../../images/leather_dyes.webp",
            "description": ""
        },
        {
            "id": 4,
            "name": "1:1 Metal Complex Dyes",
            "image": "../../images/1_1_metal_complex_dyes.webp",
            "description": ""
        },
        {
            "id": 5,
            "name": "Naphthol Dyes",
            "image": "../../images/naphthol_dyes.webp",
            "description": ""
        },
        {
            "id": 6,
            "name": "Disperse Dyes",
            "image": "../../images/disperse_dyes.webp",
            "description": ""
        },
        {
            "id": 7,
            "name": "Phthalogen Blue If3gm - Ingrain Blue 2:1",
            "image": "../../images/phthalogen_blue_if3gm_ingrain_blue_2_1.webp",
            "description": ""
        },
        {
            "id": 8,
            "name": "PH Indicators",
            "image": "../../images/ph_indicators.webp",
            "description": ""
        },
        {
            "id": 9,
            "name": "Disperse Reactive Dyes",
            "image": "../../images/disperse_reactive_dyes.webp",
            "description": ""
        },
        {
            "id": 10,
            "name": "Salt Free Dyes",
            "image": "../../images/salt_free_dyes.webp",
            "description": ""
        },
        {
            "id": 11,
            "name": "Metal Complex Solvent Dyes",
            "image": "../../images/metal_complex_solvent_dyes.webp",
            "description": ""
        },
        {
            "id": 12,
            "name": "1:2 Metal Complex Dyes",
            "image": "../../images/1_2_metal_complex_dyes.webp",
            "description": ""
        },
        {
            "id": 13,
            "name": "Indigosol Vat Dyes",
            "image": "../../images/indigosol_vat_dyes.webp",
            "description": ""
        },
        {
            "id": 14,
            "name": "Indigo Carmine",
            "image": "../../images/indigo_carmine.webp",
            "description": ""
        },
        {
            "id": 15,
            "name": "Oil soluble Dyes",
            "image": "../../images/oil_soluble_dyes.webp",
            "description": ""
        }
    ],
        "Direct Dyes":[
        {
            "id": 1,
            "name": "Optimum Quality Direct Dyes",
            "image": "../../images/optimum_quality_direct_dyes.webp",
            "description": ""
        },
        {
            "id": 2,
            "name": "Direct Dyes",
            "image": "../../images/direct_dyes.webp",
            "description": ""
        },
        {
            "id": 3,
            "name": "Industrial Direct Dyes",
            "image": "../../images/industrial_direct_dyes.webp",
            "description": ""
        },
        {
            "id": 4,
            "name": "Direct Black Dyes",
            "image": "../../images/direct_black_dyes.webp",
            "description": ""
        },
        {
            "id": 5,
            "name": "Direct Black",
            "image": "../../images/direct_black.webp",
            "description": ""
        },
        {
            "id": 6,
            "name": "Lemon Chrome Pigment",
            "image": "../../images/lemon_chrome_pigment.webp",
            "description": ""
        }
    ]
    }
    
};
  
export default contextData;
  