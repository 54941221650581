import React, { useState } from 'react';

const WhatsAppChat = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleChatBox = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="whatsapp-chat">
      <div className="whatsapp-icon" onClick={toggleChatBox}>
        <i className='bx bxl-whatsapp bx-md'></i> {/* WhatsApp icon */}
      </div>

      {isOpen && (
        <div className={`whChatSec ${isOpen ? 'wh-active' : ''}`}>
          <div className="whHdSec">
            <div className="whLogo">
              <i className='bx bxl-whatsapp bx-md'></i> {/* WhatsApp logo */}
            </div>
            <div className="whText">
              <h4>Raise your Query</h4>
              <p>Hi! Simply click below and type your query.</p>
            </div>
          </div>
          <div className="whChatCont">
          <div className="whChatList">
            <a
              className="chat-link"
              href="https://api.whatsapp.com/send?phone=919998475244"
              target="_blank"
              rel="nofollow"
            >
              <div className="green-block"></div> {/* Green block on the left */}
              <i className='bx bxl-whatsapp bx-sm'></i> {/* WhatsApp logo */}
              <div className="chat-text">
                <span className="chat-link-text">Click Here to Chat</span>
                <span className="chat-company">R H Enterprise</span>
              </div>
            </a>
          </div>
        </div>

        </div>
      )}
    </div>
  );
};

export default WhatsAppChat;
